@tailwind base;
@tailwind components;
@tailwind utilities;

.panel {
  width: 300px;
  height: 500px;
  margin: 20px;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.panel h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.card {
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: grab;
}

.card:hover {
  background-color: #eee;
}

.card:active {
  cursor: grabbing;
}

.card-list {
  height: 100%;
  overflow-y: auto;
  padding: 10px;
  border: 2px dashed #ccc;
  border-radius: 5px;
}

.card-list > .card {
  cursor: pointer;
}
